@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Neuzit';
  src: local('Neuzit'), url(./assets/fonts/NeuzeitGro-Lig.otf) format('otf');
}


body {
  font-family: 'Neuzit', 'Poppins', sans-serif;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin-top: 35px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
  } 


  
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 90%;
    }
  }


.control-dots
{
  padding: 0;
}

